import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
} from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import TWEEN from "@tweenjs/tween.js";

import { useEffect, useState } from "react";
import { Perf } from "r3f-perf";
import Floor from "./Floor.js";
import Model from "./Model.js";

export default function Experience({
  content,
  onShowBubbles,
  setIsLoading,
  autoRotation,
}) {
  const [chapterContent, setChapterContent] = useState(null);
  const [envContent, setEnvContent] = useState(null);

  const { scene, camera, gl } = useThree();

  // useFrame(() => {
  //   TWEEN.update();
  // });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setChapterContent(content);
  }, [content]);

  useEffect(() => {
    resetCam();
  }, [chapterContent]);

  const getData = () => {
    fetch("./assets/content/environment.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        handleContent(myJson);
      });
  };

  function handleContent(myJson) {
    setEnvContent(myJson);
  }

  function resetCam() {
    camera.position.set(0, 137, 750);
  }

  return (
    <>
      {envContent && (
        <>
          <PerspectiveCamera
            makeDefault
            fov={envContent.camera.fov}
            far={envContent.camera.far}
            near={envContent.camera.near}
            position={[...envContent.camera.position]}
          ></PerspectiveCamera>

          {/* <Perf position="top-left" /> */}
          {chapterContent && chapterContent.environment ? (
            <>
              <spotLight intensity={1} position={[100, 400, 100]}></spotLight>
              <Environment
                files={envContent.environment.src}
                ground={envContent.environment.ground}
              ></Environment>
            </>
          ) : (
            <>
              <axesHelper args={[200]}></axesHelper>
              <Floor></Floor>
              <Environment files={envContent.environment.src}></Environment>
            </>
          )}

          <OrbitControls
            minPolarAngle={envContent.controls.minPolarAngle}
            maxPolarAngle={envContent.controls.maxPolarAngle}
            minDistance={envContent.controls.minDistance}
            maxDistance={envContent.controls.maxDistance}
            target={[...envContent.controls.target]}
            enablePan={envContent.controls.enablePan}
            autoRotate={autoRotation}
            autoRotateSpeed={0.3}
          />
        </>
      )}

      {chapterContent?.model && (
        <Model
          onShowBubbles={onShowBubbles}
          setIsLoading={setIsLoading}
          content={chapterContent}
        ></Model>
      )}
    </>
  );
}
