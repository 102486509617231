import "./speech-area.css";
import "./bubble.css";
import "./chatcontainer.css";
import "./animations.css";
import "./loader-screen.css";
import ReactDOM from "react-dom/client";
import ExperienceContainer from "./experience/experience-container";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(<ExperienceContainer></ExperienceContainer>);
