import { Canvas } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";

import Experience from "./Experience.js";
import Speecharea from "../components/Speecharea.js";
import Chatcontainer from "../components/Chatcontainer.js";
import LoadingScreen from "../components/LoadingScreen.js";

export default function ExperienceContainer() {
  const [sceneId, setSceneId] = useState(0);
  const [textId, setTextId] = useState(0);
  const [content, setContent] = useState(null);
  const [autoRotation, setAutoRotation] = useState(false);
  const [chaptertext, setChaptertext] = useState(null);
  const [currentChapterSpeech, setCurrentChapterSpeech] = useState(null);
  const [showBubbles, setShowBubbles] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingScreen = useRef();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCurrentChapterSpeech(content?.scenes[sceneId].speechbubbles);
    setChaptertext(content?.scenes[sceneId]);
  }, [sceneId]);

  const getData = () => {
    fetch("./assets/content/script.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        handleContent(myJson);
      });
  };

  const nextTopic = () => {
    currentChapterSpeech.map((c) => {
      if (c.trackingId) {
        _paq.push(["trackEvent", "Next Button", `${c.trackingId}`]);
        // console.log(_paq);
      }
    });
    setShowBubbles(false);
    setAutoRotation(true);
    if (content.scenes.length !== sceneId + 1) {
      setSceneId(sceneId + 1);
    } else {
      setSceneId(0);
    }
  };

  function handleContent(myJson) {
    setContent(myJson);
    setChaptertext(myJson.scenes[sceneId]);
    setCurrentChapterSpeech(myJson.scenes[sceneId].speechbubbles);
  }

  const onShowBubbles = () => {
    setShowBubbles(true);
  };

  const setIsLoading = (bool) => {
    setLoading(bool);
  };

  return (
    <>
      {chaptertext && (
        <>
          <Canvas
            shadows
            gl={{ logarithmicDepthBuffer: true }}
            onClick={() => {
              setAutoRotation(false);
            }}
          >
            <Experience
              onShowBubbles={onShowBubbles}
              setIsLoading={setIsLoading}
              content={chaptertext}
              autoRotation={autoRotation}
            />
          </Canvas>

          <div className="logo-container">
            <a target="_blanc" href="https://www.click-solutions.de/">
              <img src="./assets/images/CLAC-logo.png" />
            </a>
          </div>

          {loading && (
            <LoadingScreen content={chaptertext} ref={loadingScreen} />
          )}

          <Speecharea
            showBubbles={showBubbles}
            content={currentChapterSpeech}
            nextTopic={nextTopic}
          />
          {sceneId < 2 && <Chatcontainer sceneId={sceneId}></Chatcontainer>}
        </>
      )}
    </>
  );
}
