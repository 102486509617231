import { useEffect, useState, forwardRef } from "react";

const LoadingScreen = forwardRef(function LoadingScreen(
  { content },
  loadingScreen
) {
  const [screenContent, setScreenContent] = useState();

  useEffect(() => {
    setScreenContent(content);
  }, [content]);
  return (
    <>
      {screenContent && (
        <div ref={loadingScreen} className="loading-bar">
          <div className="ani-container">
            <h1 className="loading-counter"> % loaded</h1>
            {screenContent.model.loadingText.map((t, idx) => {
              return (
                <div className="loader-text" key={idx}>
                  {t}
                  <div className="dot-container">
                    <div className="animated-dot animated-first-dot"></div>
                    <div className="animated-dot animated-second-dot"></div>
                    <div className="animated-dot animated-third-dot"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
});

export default LoadingScreen;
