import { useEffect, useState, useRef } from "react";
import Bubble from "./Bubble.js";

export default function Speecharea({ content, nextTopic, showBubbles }) {
  const [speechContent, setSpeechContent] = useState(null);
  const [isChatOpen, setChatToggler] = useState(true);
  const containerRef = useRef();
  const togglerRef = useRef();
  const animationContainer = useRef();

  useEffect(() => {
    setSpeechContent(content);
  }, [content]);

  function handleChatToggle() {
    setChatToggler(!isChatOpen);
    if (isChatOpen) {
      animationContainer.current.classList.remove("bubbleSlideIn");
      animationContainer.current.classList.add("bubbleSlideOut");
    } else {
      containerRef.current.style.height = "100vh";
      animationContainer.current.classList.remove("bubbleSlideOut");
      animationContainer.current.classList.add("bubbleSlideIn");
    }
  }

  function handleContainerAnimationEnd() {
    if (!isChatOpen) {
      containerRef.current.style.height = "3rem";
    }
  }

  return (
    <>
      {speechContent?.length > 0 ? (
        <>
          <div
            className={`speecharea__container ${
              speechContent[0].isFirstScene ? "position-first" : null
            }`}
            ref={containerRef}
          >
            {speechContent[0].isFirstScene && (
              <div className="body-header">
                <span className="timestamp">Heute</span>
                <p>Sie sind im Betrachtungsmodus</p>
              </div>
            )}

            {!speechContent[0].isFirstScene && (
              <div ref={togglerRef} className={`mobile-toggler__container`}>
                <div className="mobile-toggler">
                  <div
                    className="mobile-toggler__btn"
                    onClick={() => handleChatToggle()}
                  >
                    {isChatOpen ? (
                      <p>zeige 3D Raum</p>
                    ) : (
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.25 7.66665C0.25 3.67267 4.25908 0.583313 9 0.583313C13.7409 0.583313 17.75 3.67267 17.75 7.66665C17.75 11.6606 13.7409 14.75 9 14.75C8.09546 14.75 7.22203 14.639 6.39985 14.4326L2.51967 16.3727C2.38106 16.442 2.21543 16.4286 2.0897 16.338C1.96398 16.2474 1.89894 16.0945 1.92085 15.9411L2.43362 12.3517C1.08625 11.1148 0.25 9.47766 0.25 7.66665ZM9 1.41665C4.53618 1.41665 1.08333 4.29682 1.08333 7.66665C1.08333 9.27116 1.85298 10.751 3.15044 11.8745C3.25757 11.9673 3.31021 12.1081 3.29017 12.2484L2.85828 15.2717L6.1669 13.6173C6.258 13.5718 6.36262 13.5612 6.46101 13.5875C7.2572 13.8007 8.11096 13.9166 9 13.9166C13.4638 13.9166 16.9167 11.0365 16.9167 7.66665C16.9167 4.29682 13.4638 1.41665 9 1.41665Z"
                          fill="#ffffff"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div
              className="animation-container"
              ref={animationContainer}
              onAnimationEnd={() => {
                handleContainerAnimationEnd();
              }}
            >
              {speechContent.map((speechItem, index) => {
                return (
                  <Bubble
                    key={index}
                    showBubbles={showBubbles}
                    bubbleContent={speechItem}
                    nextTopic={nextTopic}
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
