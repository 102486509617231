import {
  useTexture,
  useVideoTexture,
  useGLTF,
  useProgress,
  ContactShadows,
} from "@react-three/drei";
import { Suspense, useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export default function Model({ onShowBubbles, content, setIsLoading }) {
  const [chapterContent, setChapterContent] = useState(null);

  useEffect(() => {
    setChapterContent(content);
    setIsLoading(true);
  }, [content]);

  function Trigger() {
    const { active, progress, loaded } = useProgress();
    useEffect(() => {
      if (!active) {
        setTimeout(() => {
          setIsLoading(false);
          onShowBubbles();
        }, 4500);
      }
    }, [active]);
  }

  function Screens({ data }) {
    if (data.model.screenSrc) {
      const { scene } = useGLTF(data.model.screenSrc);

      const rightScreen = scene.getObjectByName("screen-right");
      const leftScreen = scene.getObjectByName("screen-left");
      const screenLeftMaterial = new THREE.MeshBasicMaterial();
      const screenRightMaterial = new THREE.MeshBasicMaterial();

      leftScreen.material = screenLeftMaterial;
      rightScreen.material = screenRightMaterial;

      const videoTextureLeft = useTexture(data.model.screenLeftTex);
      const videoTextureRight = useVideoTexture(data.model.screenRightTex);
      videoTextureLeft.flipY = false;
      videoTextureRight.flipY = false;
      leftScreen.material.map = videoTextureLeft;
      rightScreen.material.map = videoTextureRight;

      setTimeout(() => {
        setIsLoading(false);
        onShowBubbles();
      }, 2000);

      return (
        <>
          <primitive
            object={scene}
            position={[...data.model.position]}
            rotation-y={data.model.rotation[1]}
            scale={data.model.scale}
          ></primitive>
        </>
      );
    }
  }

  function Model({ data }) {
    const { scene, animations } = useGLTF(data.model.src);

    if (animations.length > 0 && data.model.animate) {
      let mixer = new THREE.AnimationMixer(scene);

      animations.forEach((clip) => {
        const action = mixer.clipAction(clip);
        action.play();
      });

      useFrame((state, delta) => {
        mixer.update(delta);
      });
    }

    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (data.model.wireframe) {
          child.material.wireframe = true;
        }
        child.material.depthWrite = true;
      }
    });
    setTimeout(() => {
      setIsLoading(false);
      onShowBubbles();
    }, 2000);

    return (
      <>
        <primitive
          object={scene}
          position={[...data.model.position]}
          rotation-y={data.model.rotation[1]}
          scale={data.model.scale}
        ></primitive>
      </>
    );
  }

  function Podest({ data }) {
    const { scene } = useGLTF(data.model.podestSrc);

    scene.getObjectByName("faltenwurf_1").visible = false;
    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.depthWrite = true;
      }
    });
    setTimeout(() => {
      setIsLoading(false);
      onShowBubbles();
    }, 2000);

    return (
      <>
        <primitive
          object={scene}
          position={[...data.model.podestPosition]}
          rotation-y={data.model.rotation[1]}
          scale={data.model.scale}
        ></primitive>
      </>
    );
  }

  return (
    <>
      {chapterContent && (
        <>
          {/* <Suspense fallback={<Trigger />}> */}
          <Model data={chapterContent} />
          {/* </Suspense> */}
          {chapterContent.model.screenSrc && (
            // <Suspense fallback={<Trigger />}>
            <Screens data={chapterContent}></Screens>
            // </Suspense>
          )}
          {chapterContent.model.podestSrc && (
            // <Suspense fallback={<Trigger />}>
            <Podest data={chapterContent}></Podest>
            // </Suspense>
          )}

          <ContactShadows
            renderOrder={2}
            frames={1}
            resolution={1024}
            scale={1200}
            blur={2}
            opacity={0.7}
            far={200}
          />
        </>
      )}
    </>
  );
}
