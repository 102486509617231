import { useTexture } from "@react-three/drei";

export default function Floor() {
  const gridArgs = [2000, 50, 0x1890ff, 0x9fc6ff];

  const textureFloor = useTexture("./assets/images/grid.jpg");
  textureFloor.flipY = false;

  return (
    <>
      <mesh position-y={-1} rotation-x={-Math.PI / 2}>
        <circleGeometry attach="geometry" args={[1000, 32]}></circleGeometry>
        <meshStandardMaterial
          attach="material"
          map={textureFloor}
        ></meshStandardMaterial>
      </mesh>
      {/* <gridHelper args={gridArgs}></gridHelper> */}
    </>
  );
}
