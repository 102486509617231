import { useEffect, useState, useRef, forwardRef } from "react";

const NextButton = forwardRef(function NextButton(
  { data, nextTopic },
  nextBtn
) {
  return (
    <>
      <div className="next-btn__container">
        <button
          ref={nextBtn}
          onClick={nextTopic}
          id={data.trackingId}
          className="next-btn 
             next-consultant pulseAni"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
              stroke="#006dfb"
              strokeLinecap="round"
            />
            <circle
              cx="12"
              cy="12"
              r="3"
              stroke="#006dfb"
              strokeLinecap="round"
            />
          </svg>
          <p className="margin-left-1">Fortschritt ansehen</p>
        </button>
      </div>
    </>
  );
});

function DownloadButton({ speechContent }) {
  return (
    <>
      <div className="download-button">
        <a
          id={speechContent.whitepaperTrackingId}
          target="_blank"
          className="download-link"
          href={speechContent.downloadLink}
          onClick={() => _paq.push(['trackEvent', 'Whitepaper Button', `${speechContent.whitepaperTrackingId}`])}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 3V16" stroke="#ffffff" strokeLinecap="round" />
            <path
              d="M7 12L12 17L17 12"
              stroke="#ffffff"
              strokeLinecap="round"
            />
            <path d="M20 21H4" stroke="#ffffff" strokeLinecap="round" />
          </svg>

          <p className="cta-label">{speechContent.downloadLabel}</p>
        </a>
      </div>
    </>
  );
}

function CTAButton({ speechContent }) {
  return (
    <>
      <div className="next-btn__container">
        <button
          id={speechContent.ctaTrackingId}
          onClick={() => _paq.push(['trackEvent', 'Landingpage Button', `${speechContent.ctaTrackingId}`])}
          className="next-btn 
             more--width"
        >
          {" "}
          <a target="_blank" className="more-link" href={speechContent.ctaLink}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 7C9 7 7 7 7 7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17C7 17 9 17 9 17"
                stroke="#006dfb"
                strokeLinecap="round"
              />
              <path d="M8 12H16" stroke="#006dfb" strokeLinecap="round" />
              <path
                d="M15 17C15 17 17 17 17 17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7C17 7 15 7 15 7"
                stroke="#006dfb"
                strokeLinecap="round"
              />
            </svg>

            <p className="cta-label">{speechContent.ctaLabel}</p>
          </a>
        </button>
      </div>
    </>
  );
}

function BubbleHeaderCustomer() {
  const today = new Date();
  const h = today.getHours();
  const m = today.getMinutes();
  return (
    <>
      <div className="personname">
        <img
          className="bubble__avatar"
          src="./assets/images/pianow-avatar.png"
        />
        <p>Klavierbauer Pianow</p>
      </div>
      <span className="timestamp now-grey">{`${h < 10 ? "0" : ""}${h}:${
        m < 10 ? "0" : ""
      }${m}`}</span>
    </>
  );
}

function BubbbleHeaderConsultatnt() {
  const today = new Date();
  const h = today.getHours();
  const m = today.getMinutes();
  return (
    <>
      <div className="personname">
        <img className="bubble__avatar" src="./assets/images/avatar-team.png" />
        <p>3D Team</p>
      </div>
      <span className="timestamp now-blue">{`${h < 10 ? "0" : ""}${h}:${
        m < 10 ? "0" : ""
      }${m}`}</span>
    </>
  );
}

export default function Bubble({ bubbleContent, nextTopic, showBubbles }) {
  const [speechContent, setSpeechContent] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const bubbleItem = useRef();
  const nextBtn = useRef(null);

  useEffect(() => {
    setSpeechContent(bubbleContent);
  }, [bubbleContent]);

  useEffect(() => {
    if (speechContent) {
      bubbleItem.current.style.opacity = "0";
      nextBtn.current?.classList.remove("pulseAni");
      if (speechContent.shouldSlideEarly) {
        if (speechContent.person === "customer") {
          bubbleItem.current?.classList.add("animate-in-customer");
        } else {
          bubbleItem.current?.classList.add("animate-in-consultant");
          nextBtn.current?.classList.add("pulseAni");
        }
      }
    }
  }, [speechContent]);

  useEffect(() => {
    if (showBubbles) {
      if (
        speechContent.person === "customer" &&
        !bubbleItem.current?.classList.contains("animate-in-customer")
      ) {
        bubbleItem.current?.classList.add("animate-in-customer");
      } else if (
        !bubbleItem.current?.classList.contains("animate-in-consultant")
      ) {
        bubbleItem.current?.classList.add("animate-in-consultant");
        nextBtn.current?.classList.add("pulseAni");

        setIsTyping(true);

        setTimeout(() => {
          setIsTyping(false);
        }, 12000);
      }
    }
  }, [showBubbles]);

  function handleAnimationEnd() {
    bubbleItem.current.style.opacity = "1";
    bubbleItem.current?.classList.remove("animate-in-customer");
    bubbleItem.current?.classList.remove("animate-in-consultant");
  }

  return (
    <>
      {speechContent ? (
        <div className="bubble__container">
          <div
            ref={bubbleItem}
            onAnimationEnd={() => {
              handleAnimationEnd();
            }}
            className={`bubble ${
              speechContent.person === "customer"
                ? "bubble-customer"
                : "bubble-consultant"
            } `}
          >
            <div className="bubble__header">
              {speechContent.person === "customer" && (
                <BubbleHeaderCustomer
                  speechContent={speechContent}
                ></BubbleHeaderCustomer>
              )}
              {speechContent.person === "consultant" && (
                <BubbbleHeaderConsultatnt
                  speechContent={speechContent}
                ></BubbbleHeaderConsultatnt>
              )}
            </div>
            {isTyping ? (
              <div className="dot-container">
                <div className="animated-dot animated-first-dot"></div>
                <div className="animated-dot animated-second-dot"></div>
                <div className="animated-dot animated-third-dot"></div>
              </div>
            ) : (
              <>
                {speechContent.text && (
                  <p className="bubble__text">{speechContent.text}</p>
                )}
                {speechContent.img && (
                  <img className="bubble__image" src={speechContent.img} />
                )}
                {speechContent.hasNextBtn ? (
                  <NextButton
                    ref={nextBtn}
                    data={speechContent}
                    nextTopic={nextTopic}
                    speechContent={speechContent}
                  ></NextButton>
                ) : null}
                {speechContent.downloadLink ? (
                  <DownloadButton
                    speechContent={speechContent}
                  ></DownloadButton>
                ) : null}{" "}
                {speechContent.ctaLink ? (
                  <CTAButton speechContent={speechContent}></CTAButton>
                ) : null}
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
